import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "agreements"
    }}>{`Agreements`}</h1>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`Agreement register ("agreements") is a service for storing and retrieving agreements referred to by Entur services.`}</p>
    <h2 {...{
      "id": "model"
    }}>{`Model`}</h2>
    <p>{`TBA`}</p>
    <h2 {...{
      "id": "usage-context"
    }}>{`Usage Context`}</h2>
    <p>{`Agreements API is used by Team Partner and ... TBA`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      